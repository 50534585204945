export const TRANSLATIONS_EN = {
  welcome: "Welcome!",
  welcome1: "To keep connected with us please login with your personal info",
  loginTitle: "Login",
  signupTitle: "Company Sign Up",
  signup: "Sign Up",
  home: "Home",
  search: "search",
  ok: "Ok",
  add: "Add",
  back: "Back",
  cancel: "Cancel",
  reset: "Reset",
  verify: "Verify",
  save: "Save",
  edit: "Edit",
  next: "Next",
  previous: "Previous",
  editNext: "Edit & Next",
  submit: "Submit",
  agree: "Agree",
  finish: "Finish",
  upload: "Upload",
  print: "Print",
  delete: "Delete",
  download: "Download",
  login: "Login",
  done: "Done",
  goTitle: "Let’s go!",
  go: "Go",
  go_to_app: "Go to App",
  back_to_app: "Back to my app",
  dashboard: "Dashboard",
  activityLog: "Activity Log",
  settings: "Settings",
  searchCompany: "Search Company",
  company_detail: "Company Detail",
  myCompany: "My Company",
  more_company_detail: "Share some more company details",
  keep_me_login: "Keep me logged in on this device",
  dont_have_acc: "Don't Have An Account Yet?",
  dont_verify_acc: "Didn't Verify Account Yet?",
  clickUp: "Already registered with PharmaLinkage?",
  login_successfull: "Succesfully login!",
  add_user: "Add User",

  i_accept_the: "By creating this account I agree to PharmaLinkage's",
  terms_and_conditions: "Terms and Conditions",
  and: "and",
  privacy_policy: "Privacy Policy",
  business_code_of_conduct: "Business Code of Conduct",
  i_recieve_newsletter: "  Yes, I would like to receive the newsletter.",

  companyDetail1:
    "PharmaLinkage is committed to protecting and respecting your privacy. We only collect information that is necessary for the operation of the platform and the provision of the services you have requested from us. The registered users may send you an inquiry based on the information you provide on the PharmaLinkage platform. If you agree to be contacted by the registered users for this purpose, please check the box below:",
  companyDetail2:
    "PharmaLinkage requires you to store and process your personal data in order for you to respond to the registered user’s inquiry. If you consent to us processing your personal data for this purpose, please check the box below: ",
  inquiry_consent:
    "I agree to communicate with the registered users, to answer the inquiry.",
  data_store_consent:
    "I consent to PharmaLinkage storing and processing my data for the purpose specified above.",
  companyDetail3:
    "For additional information on our privacy practices, and how we are committed to protecting and respecting your privacy, please review the PharmaLinkage’s ",

  companyDetail4:
    "For better operation of the services, PharmaLinkage requests for corporate details of the pharmaceutical company. ",

  learn_more: "To learn more about how we protect and respect your privacy,",
  please_read1: "please read the PharmaLinkage’s",
  please_read2: "and indicate your acceptance by checking the box below:",

  for_additional:
    "For additional information on how to unsubscribe, our privacy practices, and how we are committed to protecting and respecting your privacy, please review the PharmaLinkage’s ",
  for_facility:
    "For better operation of the services, PharmaLinkage requests for details about the manufacturing facilities, unit and warehousing capabilities, packaging capabilities, plant & laboratory details.",

  facility: "Facility",
  manufacturingFacility: "Manufacturing Facility",
  viewManufacturingFacility: "View Manufacturing Facility",
  manufacturingFacilityName: "Mfg Facility Name",
  manufacturingSites: "Manufacturing Sites",
  sinceEstablished: "Since Established",
  managefacility: "Manage facility",
  addfacility: "Add facility",
  Facilitypresentation: "Facility Presentation",
  request_forCredentials: "Request for Credentials",
  register_confirm_msg: "Congratulations! Your compnay is registerd with us.",
  request_confirm_msg:
    "Your request is send to your company admin sucessfully.",
  sendRequest: "Send Request",
  verify_account: "Account Verification",
  verify_account_msg: "Please verify your email using verification code.",
  sendOTP: "Send OTP",
  emailed: "We just emailed you.",
  // val_emailed: "Please enter the code we emailed you.",
  val_emailed:
    "Please enter the code we emailed you, If you do not see the Code / OTP in your inbox please check Junk / Spam Folder.",

  confirmationCode: "Confirmation code",
  resendOTP: "Resend Code",
  did_not_receive_OTP: "Didn't receive the OTP?",
  verify_successful: "Verification Successful!",
  request_successful: "Request Submitted Successfully!",
  request_successful_text:
    "Once your admin will approve request you will get password reset link on your email",
  congratultions: "Congratulations!",
  register_successful: "Welcome to PharmaLinkage.",
  registration_successfull: "Registration Successfull!",
  registration_successfull_msg:
    "Please check your registered email for email verification.",
  forgot_password: "Forgot Password",
  forgot_password_msg:
    "Enter your e-mail address and we will send you a link to reset your password",
  mailSent_successfull: "Mail has been sent to your registered email",
  resetLink_msg: "Reset password link sent to your registered email address",
  create_new_password: "Create New Password",
  changePassword_successfull: "Password changed!",
  changePassword_confirm_msg: "Your password has been changed successfully.",
  password_notconfirm_msg: "The two passwords that you entered do not match!",
  passsword_conditions:
    "Password should be minimum 8 characters, at least one letter and one number and one special character.",
  new_company_registration: "New Company Registration",
  manageUsers: "Manage users",
  manageUser: "Manage user",
  //Promocode:
  msg_promocodedelete: "Promocode Deleted Successfully",
  pl_promo_code: "Promo Code",
  pl_promo_code_placeholder: "If you have promo code enter here",
  manage_promocode: "Promo Code",
  add_promocode: "Add Promocode",
  edit_promocode: "Edit Promocode",
  pl_promocode_description: "Description",
  pl_promocode: "Promocode",
  pl_group_name: "Group Name",
  val_promocode_description: "Please enter description",
  val_promocode: "Please enter promocode",
  val_group_name: "Please enter group name",
  msg_addPromocode: "Promocode Added Successfully",
  msg_updatePromocode: "Promocode Updated Successfully",
  manageUsersRequest: "Manage Users Request",
  addUser: "Add User",
  changePassword: "Change Password",
  myProfie: "My Profile",
  PersonaInfo: "Personal info",
  profile_completenes: "Your Profile Completeness",
  manageCompany: "Manage Company",
  model_description_concierge: "Click below button to go to Concierge Page",
  model_title_concierge: "Please Purchase Concierge",
  companyKeypersons: "Company Key Persons",
  company_corporatePresentation: "Company Corporate Presentation",
  UploadFormulations: "Upload Formulations",
  download_sample_format: "Download Sample Format",
  uploadPDF: "Upload PPT/PDF",
  bulkupload_fileType: "Accept file type: .csv",
  upload_excel_option: "Upload Excel Option",
  upload_fileType: "(.pdf, .ppt, .pptx, .doc, .docx)",
  upload_fileSize: "Maximum file size: 50 MB",
  upload_fileSizeErr: "File size exceeds the maximum allowed limit",
  upload_imagefileType: "Allowed file format: .jpg, .png",
  // upload_fileSizeErr: "File extension is not allowed!",
  upload_fileTypeErr: "This file is not an accepted format.",
  videolink_URL: "Presentation Link",
  Company_establishmentDate: "Company Establishment Date",
  no_of_manufacturingFacilities: "No. Of Manufacturing Facilities",
  employees: "Employees",
  updatePassword: "Update Password",
  userName: "UserName",
  firstName: "First Name",
  lastName: "Last Name",
  aboutMe: "About Me",

  businessLounge: " Business Lounge",
  whatsappNo: "Phone / WhatsApp No.",
  whatsapp_tooltip:
    "You can change your mobile number from profile edit option",
  verifyMobile: "Please verify your mobile",
  we_just_message_you: "We just message you.",
  enter_OTP_we_message_you: "Please enter the OTP we message you.",
  enterOTP: "Enter OTP",
  didnt_recieve_code: "Didn't recieve code?",
  time_remaining: "Time Remaining",

  support: "Support",
  tickets: "Tickets",
  create_ticket: "Create Ticket",
  manage_ticket: "Manage Ticket",
  pending: "Pending",
  inprocess: "Inprocess",
  closed: "Closed",
  resolved: "Resolved",
  need_clarification: "Needs Clarification",
  need_help: "Need some help?",
  need_help_msg1:
    "Check out our documentation or reach out to our support team via",
  need_help_msg2: "the form below. We’re happy to help!",
  describe_issue:
    "Please describe your question or issue in detail. include any attachments via URL (using a service like dropbox or google drive).",
  send_request: "Send Request",
  send_ticket_successfull: "Your Support Ticket Submitted!",
  ticket_mail_sent: "Our team will revert on it as soon as early",
  update_ticket_successfull: "Ticket updated successfully",

  sentInquiry: "Sent Inquiry",
  receivedInquiry: "Received Inquiry",
  inquiryList: "Inquiry list",
  //Received Inquiry

  pl_demo_date_time: "Inquiry Date",
  how_old_it_is: "How Old It is? (days)",
  receivingfacility_user_phone: "Receiving Facility User Phone",
  senderfacility_user_phone: "Sender Facility User Phone",
  pl_sendercompany_email: "Sender Company Email",
  sendercompany_email: "Sender Company Email",
  pl_receivingcompany_email: "Receiving Company Email",
  receivingcompany: "Receiving Company",
  pl_receivingcompany: "Receiving Company",
  ph_sender_company: "Sender Company",
  sender_company: "Sender Company",
  receivingcompany_email: "Receiving Company Email",
  receiving_status: "Status",
  receiving_details: "Details",
  receiving_subject: "Subject",
  pl_received_inquiry_value: "Received Inquiry",
  pl_sent_inquiry_value: "Sent Inquiry",

  concierge_service: "Concierge Plans",
  concierge_name: "Concierge Name",
  request_for_price: "Request For Price",
  price_requested: "Price Requested",
  concierge_service_lbl1:
    "We provide a better linkage experience with our concierge and bespoke services. Choose the best suited option and explore endless possibilities.",
  // sell_globally_service: "Sell Globally service with PharmaLinkage concierge",
  sell_globally_service: "Service with PharmaLinkage concierge",
  business_lounge_service: "Source Globally with PharmaLinkage Business Lounge",
  business_lounge_service1: "Connect Globally with PharmaLinkage Subscription",
  bespoke_service: "Bespoke Services with PharmaLinkage Concierge",

  unsubsubscribe_title:
    "To help us improve our services, we would be grateful if you could tell us why:  ",
  unsubscribe: "Unsubscribe",
  reason: "Reason",
  other_reason: "Other Reason",
  pl_reason: "Please provide your reason here…",
  val_reason: "Please provide your reason",

  facilityType: "Facility Type",
  addfacilityType: "Add Facility Type",
  msg_facilityUpdated: "Facility updated successfully",
  msg_facilityDeleted: "Facility deleted successfully",
  my_manufacturing_facility: "My Manufacturing Facility",
  manufacturing_facility: "Manufacturing Facility",
  manage_facility: "Manage facility",
  add_facility: " Add Facility",
  edit_facility: "Edit Facility",
  detail: "Detail",
  manufacturing_capacity: "Manufacturing Capacity",
  total_quantity: "Total Quantity (MT) / per month",
  add_facility_api_successfull: "Facility API created successfully",
  update_facility_api_successfull: "Facility API updated successfully",
  products: "Products",
  countries: "Countries",
  manufacturingFrom: "Manufacturing From",
  supplyingto: "Supplying To",
  api: "API",
  // addProduct: "Add Product",
  addProduct1: "Products List",
  addProductDescription:
    "Please fill the below detail and save it for adding your each product.",

  addProduct: "Plant Capacity & Product Details",
  bulkProductUpload: "Bulk Product Upload",
  plantLab: "Plant and Laboratory",
  photoGallery: "Photo Gallery",
  CDMOFormulation: "CDMO Formulation",
  err_facility_download: "Error in download facility details",
  err_send_inquiry: "Error sending inquiry",
  msg_sent_inquiry: "Inquiry sent successfully!",
  msg_success_update_inquiry: "Inquiry updated successfully!",
  msg_facility_like: "Facility shortlisted!",
  msg_facility_unlike: "Facility removed from shortlisted",

  plant_machinery_details: "Plant & Machinery Details",
  lab_instrument_details: "Laboratory Instrument & QC Set-Up Details",
  msg_uploadSuccess: "Upload Successfully",
  msg_deleteFileSuccess: "File Deleted Successfully",
  msg_deleteImageSuccess: "Image Deleted Successfully",
  err_uploadSuccess: "Err in upload",
  err_deleteSuccess: "Err in delete",

  utilities: "Utilities",
  addUtilities: "Add Utilities",
  utilitiesFile: "Utilities File",
  // utilitiesFileUpload: "Utilities File Upload",
  utilitiesFileUpload: "Water Treatment Plant Document Upload",
  utilitiesFileDocument: "Water Treatment Plant Document",
  nitrogen_generation_plant: "Nitrogen Generation Plant",

  formulation: "Formulation",
  addFormulation: "Formulation Details",
  bulkFormulationUpload: "Bulk Formulation Upload",
  addFormulationDescription: "Add Formulation Description",
  pl_formulation_name: "Formulation Name",
  val_formulation_name: "Please enter formulation name",

  manageConciergeService: "Manage Concierge ",
  addConciergeService: "Add Concierge",
  editConciergeService: "Edit Concierge ",

  websiteRequestedConcierge: "Website Requested Concierge",
  requestedConcierge: "Requested Concierge",
  purchasedConcierge: "Purchased Subscription",
  payment_fail: "Unfortunately, the payment for this order has failed",
  payment_successful:
    "Your payment was successful. Your subscription will be updated in your account.",
  payment_successful2:
    "Your payment was successful. Your subscription will be activate in your account.",
  back_to_concierge: "back to concierge",
  buyNow: "Buy Now",
  invoice: "Invoice",

  companyList: "Company list",
  shortlistedCompany: "Shortlisted Company",
  shortlistedCompanyFacilities: "Short Listed Company Facilities",

  subscribed_newsletter: "Subscribed Newsletter",
  unsubscribed_newsletter: "Unsubscribed Newsletter",
  registered_user_newsletter: "Registered User Newsletter",
  unregistered_user_newsletter: "Unregistered User Newsletter",
  website_user_newsletter: "Website User Newsletter",
  subscribed_website_user_newsletter: "Subscribed Website User Newsletter",
  unsubscribed_website_user_newsletter: "Unsubscribed Website User Newsletter",
  dwl_registered_user_newsletter: "Download Subscribed User Report",
  dwl_unregistered_user_newsletter: "Download Unsubscribed User Report",

  // PlaceHolders / Lables

  pl_company: "Company",
  pl_companyName: "Company Name",
  pl_typeCompanyName: "Company Name Type Here",
  pl_mfg_facility_location: "Mfg. Facility Location",
  pl_personName: "Person Name",
  pl_name: "Name",
  pl_phone: "Phone",
  pl_ContactNo: "Contact number",
  pl_designation: "Designation",
  pl_password: "Password",
  pl_current_password: "Current Password",
  pl_newpassword: "New Password",
  pl_confirm_password: "Confirm Password",
  pl_email: "Email Address",
  pl_personalEmail: "Official Email Id",
  pl_personMobile: "Contact / Mobile Number",
  pl_msg: "Message",
  pl_country: "Country",
  pl_chooseCountry: "Choose Country",
  pl_state: "State",
  pl_city: "City",
  pl_landmark: "Landmark",
  pl_pincode: "Pin code / Zip code",
  pl_website: "Website",
  pl_corporateAddress: "Corporate Address",
  pl_addressLine1: "Address Line 1",
  pl_addressLine2: "Address Line 2",
  pl_headOffice_email: "Head Office Email",
  pl_headOffice_contactNo: "Head Office Contact Number",
  pl_company_RegistrationNo: "Company Registration No",
  pl_company_establishedDate: "Company Establishment date",
  pl_gstNo: "GSTN",
  pl_manufacturing_fromCountry: "Manufacturing from country",
  pl_supplying_toCountries: "Supplying to Countries",
  pl_how_did_you_hear_about_us: "How did you hear about us",
  pl_facility_email: "Facility Email",
  pl_facility_contact_number: "Facility Contact Number",
  pl_address_line_1: "Address line 1",
  pl_address_line_2: "Address line 2",

  pl_no_of_manufacturing_facilities: "No. Of Manufacturing Facilities",
  pl_manufacturing_country: "Manufacturing From Country",
  pl_supplying_country: "Supplying To Countries",
  pl_video_url: "Enter Presentation Link",
  pl_about_company: "About Company",
  pl_about_me: "About Me",
  pl_user_name: "User Name",
  pl_first_name: "First Name",
  pl_last_name: "Last Name",
  pl_search: "search",
  pl_facility_location: "Facility Location",

  pl_company_email: "Company Email",
  pl_user_role: "User Role",
  pl_message: "Message",
  pl_reply_to: "Reply To",
  pl_reason_contacting_support_lable: "Reason For Contacting Support",
  pl_reason_contacting_support: "Why Are You Contacting Support?",
  pl_how_help: "How Can We Help You",

  pl_choose_company: "Choose Company",
  pl_MfgFacilityLocation: "Mfg. Facility Location",
  pl_facilityemail: "Facility email",
  pl_regulatory_approvals: "Regulatory Approvals",

  // pl_total_quantity_mt: "Total Quantity (MT/month)",
  pl_total_quantity_mt: "Total Quantity (MT) / per month",
  pl_Manufacturing_capacity_qty_month: "Manufacturing capacity (qty/month)",

  pl_manufacturing_capacity_kl: "Manufacturing Capacity (KL)",
  pl_facility_reaction_capability: "Manufacturing Reaction Capability",
  pl_decomposition_reaction: "Decomposition Reaction",
  pl_product_name: "Product Name",
  pl_CAS_no: "CAS No",
  pl_therapeutic_segment: "Therapeutic Segment",
  pl_dmf_cep: "Information on Regulatory Approvals",

  pl_description_segrigation: "Brief Description (Area Segrigation)",
  pl_no_of_classified_area: "No of Classified Areas",
  pl_classification: "Classification",

  pl_Water_treatment_system: "Water Treatment Plant",
  pl_Water_treatment_capacity: "Water Treatment Capacity",
  pl_capacity_UOM: "Capacity (litre/hrs)",
  pl_type_of_utility: "Type of utility",
  pl_capacity: "Capacity",
  pl_purity: "Purity",
  pl_remark: "Remark",
  pl_nitrogen_capacity: "Capacity (nm3/hr)",
  pl_nitrogen_capacity_utility: "Capacity",
  pl_nitrogen_purity: "Purity (%) vol.",
  otherUtilities: "Other Utilities",

  pl_type_of_formulation: "Type of Formulation",
  pl_monthly_capacity: "Capacity per month",
  pl_monthly_capacity_formulation: "Capacity (qty/month)",
  pl_formulation_description: "Formulation Description",

  pl_concierge_type: "Concierge Type",
  pl_package_type: "Package Type",
  pl_actual_price: "Actual Price (INR)",
  pl_discount: "Discount (%)",
  pl_sell_price: "Offer Price (INR)",
  pl_duration: "Duration (days)",
  pl_description: "Description",
  pl_description_1: "Brief Description",
  pl_description_segrigation_1: "Brief Description (Area Segrigation)",
  pl_actual_price_usd: "Actual Price (USD)",
  pl_discount_usd: "Discount (% USD) ",
  pl_sell_price_usd: "Offer Price (USD)",
  val_actual_price_usd: "Please Enter Actual Price",
  val_discount_usd: "Please Enter Discount",
  val_sell_price_usd: "Please Enter Sell Price",

  pl_super_admin: "Super Admin",
  pl_sub_admin: "Sub Admin",
  pl_select_sub_admin: "Select Sub Admin",
  pl_current_month_onboarded_companies: "Current Month Onboarded Companies",
  pl_total_sales: "Total Sales",
  pl_monthly_sales: "Monthly Sales",
  pl_yearly_sales: "Yearly Sales",
  pl_open_ticket_list: "Open Ticket List",
  pl_company_inquiry_status: "Company Inquiry Status",
  pl_total_api_facility: "Total Api Facility",
  pl_total_formulation_facility: "Total Formulation Facility",
  pl_total_CDMO_facility: "Total CDMO Facility",
  pl_total_open_inquiry: "Total Open Inquiry",
  pl_total_close_inquiry: "Total Close Inquiry",
  pl_current_month_open_inquiry: "Current Month Open Inquiry",
  pl_total_active_conciarge: "Total Active Conciarge",
  pl_total_inactive_conciarge: "Total Inactive Conciarge",
  pl_total_tickets: "Total Tickets",
  pl_in_completed_profile: "In Completed Profile",
  pl_subscription_end_30_days: "Subscription End 30 Days",
  pl_country_wise_company: "Country Wise Company",
  message_to_buy_subscription:
    "Access to unlimited manufacturing facilities and product search Onboard unlimited Manufacturing facilities of your company Unlimited Users per facility Prompt Chat and Email support Access to many more such services",
  please_buy_subscription: "Upgrade to Premium",

  // Value msg

  val_name: "Please enter name",
  val__name: "Please enter valid name",
  val_personName: "Please enter person name",
  val_companyName: "Please enter company name",
  val_phone: "Please enter valid phone",
  val_designation: "Please enter designation",
  val_password: "Please enter password",
  val_confirm_password: "Please enter confirm password",
  val_email: "Please enter valid email!",

  val_terms_and_conditions: "Please accept terms and conditions",
  val_inquiry_consent: "Please accept inquiry consent",
  val_data_store_consent: "Please accept data store consent",
  err_getConsent: "error in consent",

  val_registrationNo: "Please enter valid registration no!",
  val_mfg_facility_location: "Please enter mfg. facility location",
  val_facility_location: "Please select facility location",
  val_msg: "Please enter message",
  val_company: "Please select company",
  val_country: "Please select country",
  val_state: "Please enter state",
  val_city: "Please enter city",
  val_landmark: "Please enter landmark",
  val_pincode: "Please enter valid Pin code/zip code",
  val_website: "Please enter valid URL",
  val_corporateAddress: "Please enter corporate address",
  val_addressLine: "Please enter valid address",
  val_addressLine1: "Please enter address line 1",
  val_addressLine2: "Address Line 2",
  val_gstNo: "Please enter valid GST No.",
  val_company_RegistrationNo: "Please enter company registration No",
  val_no_of_manufacturing_facilities:
    "Please enter No. of manufacturing facilities",
  val_manufacturing_country: "Please select manufacturing from country",
  val_supplying_country: "Please select supplying to Countries",
  val_video_url: "Please enter valid link",
  val_number: "Please enter valid number",
  val_about_me: "Please enter about me",

  val_reply_to: "Please enter reply to",
  val_how_help: "Please describe your question or issue in detail",
  val_reason_contacting_support: "Please enter reason for contacting support",
  val_user_role: "Please Select User Role",

  val_Mfg_Facility_Location: "Please enter Mfg facility location",
  val_facility_type: "Please select facility type",
  val_facility_regulator: "Please select facility regulatory",

  val_manufacturing_capacity: "Please enter manufacturing capacity (in kl)",
  val_total_quantity: "Please enter total quantity / per month",
  val_facility_reaction_capability:
    "Please select manufacturing reaction capability",
  val_product_name: "Please enter product name",
  val_CAS_no: "Please enter CAS No.",
  val_therapeutic_segment: "Please select therapeutic segment",
  val_dmf_cep: "Please select Information on Regulatory Approvals",

  val_type_of_formulation: "Please select type of formulation",
  val_monthly_capacity: "Please enter capacity per month",

  val_no_of_classified_area: "Please enter no. of classified area",
  val_classification: "Please enter valid classification",

  val_type_of_utility: "Please enter type of utility",
  val_capacity: "Please enter capacity",
  val_capacity1: "Please enter valid capacity",
  val_purity: "Please enter purity",

  val_Water_treatment_system: "Please enter water treatment plant ",
  val_concierge_type: "Please select concierge type",
  val_package_type: "Please select package type",
  val_actual_price: "Please enter actual price",
  val_discount: "Please enter discount",
  val_sell_price: "Please enter sell price",
  val_duration: "Please enter duration",
  val_description: "Please enter description",

  // Length msg

  minlength_msg: "value must be minimum 2 characters",
  maxlength_personName: "value must be maximum 15 characters",
  maxlength_designation: "value must be maximum 15 characters",
  maxlength_msg: "value must be maximum",
  characters: " characters",
  fileSize5MB: "Upload PPT / PDF - Maximum file size: 2 MB",
  photoGalleryCondition: "Upload JPG/PNG  - Maximum 5 photos limit",

  // Notifications msg

  msg_login_successfull: "Login Successfully",
  msg_PasswordChangeSuccess: "Password Changed Successfully",
  err_EnterCorrectPassword: "Please Enter Correct Password",
  already_activated: "Your account is already activated!",
  msg_userUpdateSuccess: "User updated successfully",
  err_editProfile: "Error in edit profile",
  err_facility: "Error in facility details",
  err_getFacility: "Error in get facility details",
  err_addfacility: "Error in add facility details",
  err_facilityUpdated: "Error in update facility details",
  addUser_successfull: "User Created!",
  update_active_successfull:
    "User Activated Successfully and Credential detail emailed to User.",
  update_inactive_successfull: "User Deactivated and email sent",

  company_active_successfull:
    "Company Activated Successfully and Credential detail emailed to Company.",
  company_inactive_successfull: "Company Deactivated!",
  updatedCompanyDetailSuccessful: "Company Detail Updated successfully",
  companyDeleted: "Company deleted successfully",
  next_step_guide: "Next Step Guide",

  msg_add_reaction_capability: "Reaction Capability added successfully",
  msg_delete_reaction_capability: "Reaction Capability deleted successfully",

  msg_addProductSuccess: "Product added successfully",
  msg_updateProductSuccess: "Product updated successfully",
  msg_productDeleted: "Product deleted successfully",

  msg_addtherapeuticSegmentSuccess: "Therapeutic Segment added successfully",
  msg_therapeuticSegmentDeleted: "Therapeutic Segment deleted successfully",
  err_enterTherapeutic_segmentField: "Please enter therapeutic segment",

  msg_addDmfCepSuccess: "Regulatory Approvals added successfully",
  msg_dmfCepDeleted: "Regulatory Approvals deleted successfully",
  err_enterDmfCepField: "Please enter Information on regulatory approvals",

  msg_addformulationTypeSuccess: "Formulation type added successfully",
  msg_formulationTypeDeleted: "Formulation type deleted successfully",
  err_enterFormulation_typeField: "Please enter formulation type",

  msg_addFormulationDescriptionSuccess:
    "Formulation description added successfully",
  msg_updateFormulationDescriptionSuccess:
    "Formulation description updated successfully",
  msg_formulationDescriptionDeleted:
    "Formulation description deleted successfully",

  msg_addWarehousingCapabilitiesSuccess: "Descriptions saved successfully",

  msg_addClassificationSuccess: "Classification added successfully",
  msg_updateClassificationSuccess: "Classification updated successfully",
  msg_classificationDeleted: "Classification deleted successfully",
  err_classificationSuccess: "Error in classification",

  msg_addUtilitiesSuccess: "Utilities added successfully",
  msg_updateUtilitiesSuccess: "Utilities updated successfully",
  msg_utilitiesDeleted: "Utilities deleted successfully",
  err_utilitiesSuccess: "Error in utilities",

  msg_addWaterTreatmentSuccess: "Water Treatment Plant added successfully",
  msg_updateWaterTreatmentSuccess: "Water Treatment Plant updated successfully",
  msg_waterTreatmentDeleted: "Water Treatment Plant deleted successfully",
  err_waterTreatmentSuccess: "Error in water treatment plant",

  msg_nitrogenPlantGenerateSuccess: "Nitrogen Plant Generate successfully",

  msg_addFormulationSuccess: "Formulation added successfully",
  msg_updateFormulationSuccess: "Formulation updated successfully",
  msg_formulationDeleted: "Formulation deleted successfully",

  msg_addFormulationDosageSuccess: "Formulation Dosage added successfully",
  msg_updateFormulationDosageSuccess: "Formulation Dosage updated successfully",
  msg_formulationDosageDeleted: "Formulation Dosage deleted successfully",

  msg_addConciergeSuccess: "Concierge added successfully",
  msg_updateConciergeSuccess: "Concierge updated successfully",
  msg_ConciergeDeleted: "Concierge deleted successfully",

  msg_requestDeleted: "Request deleted successfully",
  msg_requestUpdated: "Request updated successfully",

  company_exsits: "Company name already exsits",
  location_exsits: "Mfg. Facility location already exsits",
  // Pop-ups

  pop_unsubscribe: "Are you sure to unsubscribe ?",

  pop_title: "Are you sure to delete ?",
  pop_photoTitle: "Are you sure delete this image ?",

  pop_ok: "Yes",
  pop_cancel: "Cancel",

  // confirmAlert
  // confirmAlert_unsubscribe: "Confirm to update status ?",

  confirmAlert_title: "Confirm to update status ?",
  confirmAlert_message: "Are you sure to do this ?",
  confirmAlert_ok: "Yes",
  confirmAlert_cancel: "Cancel",

  // confirmAlertFacility

  confirmAlertFacility_title: "Confirm to save change ?",
  confirmAlertFacility_message: "Are you sure want to save the change ?",
  confirmAlertFacility_ok: "Yes",
  confirmAlertFacility_cancel: "No",

  // confirmAlertBrowser

  confirmAlertBrowser_title: "Leave site?",
  confirmAlertBrowser_message: "Changes you made may not be saved.",
  confirmAlertBrowser_ok: "Leave",
  confirmAlertBrowser_cancel: "Cancel",

  // Send Inquiry
  more: "More",
  moreInfo: "More info",
  sendInquiry: "Send Inquiry",
  sendinquiry_from_email: "From Email",
  ph_sendinquiry_from_email: "From Email",
  sendinquiry_to_email: "Inquiry To",
  ph_sendinquiry_to_email: "Inquiry To",
  sendinquiry_to_company: "To Company Name",
  ph_sendinquiry_to_company: "To Company Name",
  sendinquiry_subject: "Subject",
  ph_sendinquiry_subject: "Please enter subject",
  val_msg_sendinquiry_subject: "Please enter subject",
  sendinquiry_details: "Details",
  ph_sendinquiry_details: "Please enter details",
  val_msg_sendinquiry_details: "Please enter details",

  //Next previous dialog messages
  model_title: "Unsaved Changes!",
  // model_description_message:"You are about to leave this page without saving. All changes will be lost. Do you really want to leave without saving?",
  model_description_message: "Do you really want to leave without saving?",
  button_back: "Leave Without Saving",
  button_save: "Go Back and Save Changes",

  model_title_required: "Incomplete Information!",
  model_description_required:
    "You're almost there! Please check and fill in the missing required fields before continuing.",
  button_back_required: "Go Back",

  //Regulatory Approvals
  manage_regulatory_approval: "Global Health Regulators",
  edit_regulatory_approval: "Edit Health Regulator",
  add_regulatory_approval: "Add Health Regulator",
  pl_regulatory_name: "Regulator Name",
  val_regulatory_name: "Please Enter Valid Name",
  pl_regulatory_description: "Regulator Description",
  val_regulatory_description: "Please Enter Valid Description",
  msg_regulatorydelete: "Regulator Deleted Successfully",
  msg_deleteRegulatoryFileSuccess: "File Deleted Successfully",
  regulatory_added_successfully: "Regulator Added Successfully",
  error_in_adding_regulatory: "Error In Adding Regulator",
  regulatory_updated_successfully: "Regulator Updated Successfully",
  error_in_update_regulatory: "Error In Updating Regulator",
  regulatory_file: "Regulator File",
};
